import { Type } from 'class-transformer'
import Required from '../validate/validators/Required'
import { GeoLocation } from './GeoLocation'
import { UkRegion } from './uk-regions'

export class AddressGoogle {
    @Required()
    name: string = undefined

    @Required()
    display: string = undefined

    @Required()
    postcode: string = undefined

    locality: string = undefined

    town: string = undefined

    city: string = undefined

    county: string = undefined

    @Required('Please enter a valid UK address')
    region: UkRegion = undefined

    country: string = undefined
    countryCode: string = undefined

    @Type(() => AddressComponent)
    addressComponents: AddressComponent[]
    @Type(() => GeoLocation)
    location: GeoLocation
}

export class AddressComponent {
    longName: string
    shortName: string
    types: string[]
}
